document.addEventListener('DOMContentLoaded', function () {

    const latvianMonths = [
        'Janvāris', 'Februāris', 'Marts', 'Aprīlis', 'Maijs', 'Jūnijs',
        'Jūlijs', 'Augusts', 'Septembris', 'Oktobris', 'Novembris', 'Decembris'
    ];

    // Get all table cells that represent days in the calendar
    var tableCells = document.querySelectorAll('.calendar td');

    // Get the div that will display the highlighted day details
    var highlightDiv = document.querySelector('[data-day-highlight]');

    // Get the current month from form
    let currentMonthIndex = null;
    const calendarTable = document.querySelector('table[month]');

    // Check if the table with the 'month' attribute exists
    if (calendarTable) {
        // Get the value of the 'month' attribute
        currentMonthIndex = calendarTable.getAttribute('month') - 1;
    } else {
        currentMonthIndex = new Date().getMonth(); // 0 = January, 11 = December
    }

    // Get the current month (0-11) and map it to the Latvian month names
    const currentMonthName = latvianMonths[currentMonthIndex];

    // Add a click event listener to each table cell
    tableCells.forEach(cell => {
        cell.addEventListener('click', function () {
            // Get the day number from the cell
            if (cell.innerHTML) {
                // Remove the 'is-active' class from all cells
                tableCells.forEach(c => c.classList.remove('is-active'));

                // Add the 'is-active' class to the clicked cell
                cell.classList.add('is-active');

                const dayNumberElement = cell.querySelector('.day-number');
                const dayNumber = dayNumberElement ? dayNumberElement.textContent : '';
                const dayNumberHref = dayNumberElement ? dayNumberElement.href : '#';

                // Get the names from the cell
                const names = [];
                const nameElements = cell.querySelectorAll('.name-link');
                nameElements.forEach(nameElement => {
                    names.push({
                        title: nameElement.textContent,
                        url: nameElement.href
                    });
                });

                // Update the highlightDiv content
                if (highlightDiv) {
                    // Clear the existing content
                    highlightDiv.innerHTML = '';

                    // Create and append the day number and month name
                    if (dayNumber) {
                        // Create an <a> element for the month and day number
                        const dayMonthLink = document.createElement('a');
                        dayMonthLink.classList.add('day-number'); // Add the same class
                        dayMonthLink.href = dayNumberHref; // Use the same link from the original day number element
                        dayMonthLink.textContent = `${dayNumber} ${currentMonthName}`;
                        highlightDiv.appendChild(dayMonthLink);
                    }

                    // Create and append the names
                    names.forEach(name => {
                        const nameLink = document.createElement('a');
                        nameLink.href = name.url;
                        nameLink.textContent = name.title;
                        nameLink.classList.add('name-link'); // Add the same class

                        highlightDiv.appendChild(nameLink);

                        // Add a line break for visual separation
                        // highlightDiv.appendChild(document.createElement('br'));
                    });
                }
            }
        });
    });

    document.querySelectorAll('.burger-button').forEach(function (button) {
        button.addEventListener('click', function () {
            // Get the mobile menu
            var mobileMenu = document.getElementById('mobileMenu');

            // Toggle the 'active' class to show or hide the menu
            if (mobileMenu) {
                mobileMenu.classList.toggle('active');
            }

            // Toggle the 'open' class on the burger button
            button.classList.toggle('open');

            // Disable or enable scrolling by toggling a class on the body
            if (mobileMenu.classList.contains('active')) {
                // If menu is active, disable scrolling
                document.body.classList.add('no-scroll');
            } else {
                // If menu is not active, enable scrolling
                document.body.classList.remove('no-scroll');
            }
        });
    });

    document.querySelector('.mobile-nav .search-bar-icon').addEventListener('click', function (e) {

        let input = document.querySelector('.mobile-nav .search-bar');
        e.preventDefault();

        if (input) {

            if (input.classList.contains('is-active')) {

                //document.querySelector('.mobile-nav #searchform').submit();
            } else {

                if (document.getElementById('mobileMenu').classList.contains('active')) {
                    document.querySelector('.burger-button').click();
                    console.log('aa');
                }

                input.classList.add('is-active');
                input.classList.add('mobile-header-search-radius');

                this.classList.add('no-border');
                input.focus();
            }
        }
    });

    document.querySelector('.mobile-nav .search-bar').addEventListener('blur', function () {
        let input = this;
        let searchButton = document.querySelector('.mobile-nav .search-bar-icon');

        setTimeout(function () {
            input.classList.toggle('is-active');
            input.classList.remove('mobile-header-search-radius');

            if (searchButton) {
                searchButton.classList.remove('no-border');
            }
        }, 200);

    });

    function setupSearchForm(searchInputId, nameListId) {
        const searchInput = document.getElementById(searchInputId);
        const availableNamesDiv = document.getElementById(nameListId);
        const nameItems = availableNamesDiv.getElementsByClassName('name-item');

        if (searchInput && availableNamesDiv) {
            const noResultsMessage = document.createElement('div');
            noResultsMessage.classList.add('no-results-message');
            noResultsMessage.textContent = 'Atbilstoši vārdi nav atrasti.';
            noResultsMessage.style.display = 'none'; // Initially hide the message
            availableNamesDiv.appendChild(noResultsMessage); // Add the message to the DOM

            // Show available names when typing
            searchInput.addEventListener('input', function () {
                const filter = this.value.toUpperCase();

                // Normalize filter to remove diacritics
                const normalizedFilter = filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                let matchesFound = false; // Track if any names match the search

                for (let i = 0; i < nameItems.length; i++) {
                    const nameText = nameItems[i].textContent || nameItems[i].innerText;

                    // Normalize name text to remove diacritics
                    const normalizedNameText = nameText.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                    if (normalizedNameText.toUpperCase().indexOf(normalizedFilter) > -1) {
                        nameItems[i].style.display = ""; // Show item
                        matchesFound = true; // A match is found
                    } else {
                        nameItems[i].style.display = "none"; // Hide item
                    }
                }

                // Show the name list and display no results message if no matches found
                availableNamesDiv.style.display = 'block'; // Keep the div always visible
                noResultsMessage.style.display = matchesFound ? 'none' : 'block'; // Show message if no matches
            });

            // Add functionality to open permalink when a name is clicked
            availableNamesDiv.addEventListener('click', function (event) {
                if (event.target && event.target.classList.contains('name-item')) {
                    const permalink = event.target.getAttribute('data-permalink');
                    window.location.href = permalink; // Redirect to the permalink
                }
            });

            // Add blur effect to hide the name list when the input loses focus
            searchInput.addEventListener('blur', function () {
                setTimeout(function () {
                    availableNamesDiv.style.display = 'none'; // Hide the name list
                    searchInput.classList.remove('header-name-search')
                }, 200); // Add a slight delay to allow clicks to register
            });

            // Add focus effect to show the name list when input is focused again
            searchInput.addEventListener('focus', function () {
                availableNamesDiv.style.display = 'block'; // Show the name list when focused
                searchInput.classList.add('header-name-search')
            });
        }
    }

    setupSearchForm('header-name-search', 'header-name-list'); // Desktop search
    setupSearchForm('mobile-header-name-search', 'mobile-header-name-list'); // Mobile search

});